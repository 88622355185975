// eslint-disable-next-line @typescript-eslint/no-var-requires
const width = require('./tailwind.options')

// eslint-disable-next-line immutable/no-mutation
module.exports = {
  darkMode: 'class',
  custom: {
    width,
  },
  mode: 'jit',
  purge: ['./src/**/*.{tsx,jsx}'],
  experimental: {
    optimizeUniversalDefaults: true,
  },
  theme: {
    fontFamily: {
      sans: ['Roboto', 'Arial'],
    },
    colors: {
      inherit: 'inherit',
      back: '#ffffff',
      backDark: '#1F2123',
      backBodyDark: '#181a1b',
      backAccent: '#ccffcb',
      backAccentDark: '#013b00',
      backScreen: '#F6F6F7',
      backScreenDark: '#6f777c',
      backOpaque: '#eeeded',
      backOpaqueDark: '#3d3f44',
      backNegative: '#ffcccc',
      backNegativeDark: '#5b0000',
      backNegativeLight: '#fff4f4',
      primary: '#000000',
      primaryDark: '#BBBEC2',
      primaryAccentDark: '#E8EBED',
      opaque: '#666666',
      opaqueDark: '#9599a0',
      opaqueStrong: '#404040',
      opaqueLight: '#999999',
      opaqueLightest: '#c4c4c4',
      accent: '#267c24',
      action: '#319c2f',
      actionDark: '#2e9d2c',
      option: '#e21b1b',
      ghost: '#e7e7e7',
      ghostDark: '#4e5357',
      negative: '#af0000',
      negativeDark: '#ff3838',
      confirm: '#007aff',
      help: '#1e59cb',
      warn: '#ffa336',
    },
    extend: {
      width,
      maxWidth: width,
      opacity: {
        60: '.6',
      },
      spacing: {
        18: '4.5rem',
        7: '1.75rem',
      },
      borderWidth: {
        3: '3px',
      },
      screens: {
        greenBannerMax: width.greenBannerMax + 'px',
      },
    },
  },
  plugins: [require('@tailwindcss/line-clamp')],
}
