const webAppMobileWidth = 428
const webAppMobileTwoColumnsAdminWidth = webAppMobileWidth / 2
const webAppMenuDesktopWidthOneColumn = 844
const webAppMenuDesktopWidth = webAppMenuDesktopWidthOneColumn + 450
const webAppMenuDesktopProductWidth = 387
const greenBannerMax = 850

// eslint-disable-next-line immutable/no-mutation
module.exports = {
  webAppMobileWidth,
  webAppMobileTwoColumnsAdminWidth,
  webAppMenuDesktopWidthOneColumn,
  webAppMenuDesktopWidth,
  webAppMenuDesktopProductWidth,
  greenBannerMax,
}
