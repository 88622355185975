export function BackArrow() {
  return (
    <div className="relative h-0 w-0 cursor-pointer" style={{ top: '1.25rem', left: '1.25rem' }}>
      <div
        className="w-10 h-10 bg-back dark:bg-backDark rounded-full flex justify-center items-center"
        style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
      >
        <BackArrowIcon color="fill-primary dark:fill-primaryDark" />
      </div>
    </div>
  )
}

export function BackArrowIcon(props: { color: string }) {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      className={props.color}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path d="M0.872702 10.9838C0.286915 11.5696 0.286915 12.5193 0.872702 13.1051L10.4186 22.651C11.0044 23.2368 11.9542 23.2368 12.54 22.651C13.1257 22.0652 13.1257 21.1155 12.54 20.5297L4.05468 12.0444L12.54 3.55915C13.1257 2.97337 13.1257 2.02362 12.54 1.43783C11.9542 0.852045 11.0044 0.852045 10.4186 1.43783L0.872702 10.9838ZM22.8889 10.5444L1.93336 10.5444V13.5444L22.8889 13.5444V10.5444Z" />
    </svg>
  )
}
