import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { BackArrowIcon } from './back'
import { uiEvents } from '@/utils/event'
import { LayoutContext } from '@/components/layout'

export type OverlayProps = PropsWithChildren<{
  id: string
  title: string
}>

declare global {
  interface AppUIEventMap {
    OverlayBackClick: {
      type: 'OverlayBackClick'
      overlayId: string
    }
  }
}

export function Overlay(props: OverlayProps) {
  const layoutContext = useContext(LayoutContext)!
  const [width, setWidth] = useState(0)
  useEffect(() => {
    const layout = document.getElementById('menu-column') ?? document.getElementById('layout')!
    setWidth(layout.offsetWidth)
  }, [layoutContext.forceMobileLayout])

  useEffect(() => {
    // eslint-disable-next-line immutable/no-mutation
    document.body.style.overflow = 'hidden'
    return () => {
      // eslint-disable-next-line immutable/no-mutation
      document.body.style.overflow = 'initial'
    }
  }, [])

  if (!width) {
    return null
  }

  return (
    <div className="fixed top-0 w-full z-50">
      <div
        className="h-screen overflow-auto bg-back dark:bg-backDark pb-40"
        style={{
          width,
        }}
      >
        <div className="z-30 top-0 h-16 bg-back dark:bg-backDark flex items-center font-bold text-2xl leading-tight py-4 px-5 border-b border-ghost dark:border-ghostDark">
          <a
            className="cursor-pointer"
            onClick={() => {
              uiEvents.next({
                type: 'OverlayBackClick',
                overlayId: props.id,
              })
            }}
          >
            <BackArrowIcon color="fill-primary dark:fill-primaryDark" />
          </a>
          <h1 className="ml-3 leading-none">{props.title}</h1>
        </div>
        <div>{props.children}</div>
      </div>
    </div>
  )
}
